import React, { useEffect, useState, useContext } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import API from "../../utils/API";
import CseLayout from "./CseLayout";
import CustomButton from "../../components/buttons/CustomButton";
import {
  Label,
  Modal,
  ModalClose,
  ModalOverlay,
  Error,
  Input,
} from "../../utils/styled-components-library";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import classes from "./CseAccess.module.scss";
import { DatePickerField } from "../../components/DatePicker";
import moment from "moment";

const extratPollingStationIds = (values) => {
  return Object.entries(values)
    .map(([key, value]) => {
      if (key.includes("pollingStation") && value) {
        return key.split("pollingStation")[1];
      } else {
        return null;
      }
    })
    .filter((id) => id);
};

const AdminCard = ({
  admin,
  isSubmitting,
  setIsSubmitting,
  handleAdminSelected,
  isAdmin,
}) => {
  return (
    <div className={classes.AdminCard}>
      <div style={{ textAlign: "left" }}>
        <strong>{`${admin.csePollingAdminFirstname} ${admin.csePollingAdminLastname}`}</strong>
        <p
          style={{
            marginTop: 5,
            marginBottom: 0,
            color: "#58626F",
          }}
        >
          {admin.csePollingAdminEmail}
        </p>

        <p
          style={{
            marginTop: 5,
            marginBottom: 0,
            color: "#58626F",
          }}
        >
          Mot de passe :{" "}
          <strong>{admin.isPasswordSend ? "Envoyé" : "Non envoyé"}</strong>
        </p>
        {(admin.isStripper && (
          <p
            style={{
              marginTop: 5,
              marginBottom: 0,
              color: "#58626F",
            }}
          >
            Clef de dépouillement:{" "}
            <strong>
              {admin.isStrippingKeySend ? "Envoyé" : "Non envoyée"}
            </strong>
          </p>
        )) ||
          null}
      </div>
      {(isAdmin && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            width: 300,
          }}
        >
          {(!admin.isStripper && (
            <CustomButton
              type="submit"
              disabled={isSubmitting}
              style={{ width: "auto", padding: "8px 41px" }}
              onClick={() => {
                setIsSubmitting(true);
                API.post(
                  "/cseRouteCRUD",
                  {
                    cseId: admin.cseId,
                    csePollingAdminId: admin.csePollingAdminId,
                    path: "/setAdminStripper",
                    module: "cseStrippingCRUD",
                  },
                  (data: { success: boolean; data: any }) => {
                    setIsSubmitting(false);
                    if (data.success) {
                      toast.success(
                        `La clef de dépouillement sera envoyée à ${admin.csePollingAdminEmail} !`
                      );
                    } else {
                      if (data.message) {
                        toast.error(data.message);
                      }
                    }
                  },
                  () => {
                    setIsSubmitting(false);
                    toast.error(
                      `Une erreur est survenue. Contactez l'administrateur `
                    );
                  }
                );
              }}
            >
              <>Attribuer une clef de dépouillement</>
            </CustomButton>
          )) ||
            null}
          {(admin.isStripper && (
            <CustomButton
              type="submit"
              disabled={isSubmitting}
              className={classes.DeletePersonButton}
              style={{ width: "auto" }}
              onClick={() => {
                setIsSubmitting(true);
                API.post(
                  "/cseRouteCRUD",
                  {
                    csePollingAdminId: admin.csePollingAdminId,
                    path: "/deleteCseStrippingKey",
                    module: "cseStrippingCRUD",
                  },
                  (data: { success: boolean; data: any }) => {
                    setIsSubmitting(false);
                    if (data.success) {
                      toast.success(
                        `La clef de dépouillement a bien été supprimée !`
                      );
                    }
                  },
                  () => {
                    setIsSubmitting(false);
                    toast.error(
                      `La clef de dépouillement n'a pas pu être supprimée !`
                    );
                  }
                );
              }}
            >
              <>Annuler l'attribution de la clef</>
            </CustomButton>
          )) ||
            null}

          <CustomButton
            type="submit"
            disabled={isSubmitting}
            style={{ width: "auto" }}
            onClick={() => {
              handleAdminSelected(admin);
            }}
          >
            <>Modifier</>
          </CustomButton>
          <CustomButton
            invert
            type="submit"
            disabled={isSubmitting}
            style={{ width: "auto", padding: "8px" }}
            onClick={() => {
              setIsSubmitting(true);
              API.post(
                "/sendCseAdminsCodes",
                {
                  cseId: admin.cseId,
                  csePollingAdminId: admin.csePollingAdminId,
                },
                (data: { success: boolean; data: any }) => {
                  setIsSubmitting(false);
                  if (data.success) {
                    toast.success(
                      `Les codes ont été envoyés à ${admin.csePollingAdminEmail} !`
                    );
                  }
                },
                () => {
                  setIsSubmitting(false);
                  toast.error(
                    `Les codes n'ont pas pu être envoyés à ${admin.csePollingAdminEmail} !`
                  );
                }
              );
            }}
          >
            <>Envoyer les codes</>
          </CustomButton>
          <CustomButton
            type="submit"
            disabled={isSubmitting}
            style={{ width: "auto" }}
            className={classes.DeletePersonButton}
            onClick={() => {
              setIsSubmitting(true);
              API.post(
                "/cseRouteCRUD",
                {
                  csePollingAdminId: admin.csePollingAdminId,
                  module: "admin",
                  path: "deleteCseAdmin",
                },
                (data: { success: boolean; data: any }) => {
                  setIsSubmitting(false);
                  if (data.success) {
                    toast.success(
                      `${admin.csePollingAdminFirstname} ${admin.csePollingAdminLastname} a été supprimé !`
                    );
                  }
                },
                () => {
                  setIsSubmitting(false);
                  toast.error(
                    `${admin.csePollingAdminFirstname} ${admin.csePollingAdminLastname} n'a pas été supprimé !`
                  );
                }
              );
            }}
          >
            <>Supprimer</>
          </CustomButton>
        </div>
      )) ||
        null}
    </div>
  );
};

const CseAccess = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [isLoading, setIsLoading] = useState(true);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [csePollingStations, setCsePollingStations] = useState([]);
  const [initalPollingAdmin, setInitialPollingAdmin] = useState({});
  const [openSendModal, setOpenSendModal] = useState(false);

  const user = useContext(UserContext);
  const isAdmin = user.userType === "client";

  const handleAdminSelected = (admin) => {
    const adminPollingStations = {};
    admin.pollingStations.forEach((pollingStation) => {
      adminPollingStations["pollingStation" + pollingStation.pollingStationId] =
        true;
    });

    const newAdmin = {
      ...admin,
      ...adminPollingStations,
      isAdmin: admin.isPresident
        ? "Président"
        : admin.isAdmin
          ? "Organisateur"
          : "Assesseur",
    };
    delete newAdmin.pollingStations;
    setInitialPollingAdmin(newAdmin);
    setIsModify(true);
    setIsOpenModal(true);
  };

  useEffect(() => {
    setIsLoading(false);
    console.log("REFRESH");
    API.get(
      "/getCseAdmins",
      { cseId },
      (data: { success: boolean; data: any }) => {
        setCsePollingStations(data.data);
        setIsLoading(false);
      }
    );
  }, [isOpenModal, cseId, isSubmitting]);

  const admins = csePollingStations
    .map((poll) => {
      return poll.admins;
    })
    .flat();

  const duplicateIds = admins
    .map((v) => v.csePollingAdminId)
    .filter((v, i, vIds) => vIds.indexOf(v) !== i);
  const centralAdmins = admins
    .filter((obj) => {
      return duplicateIds.includes(obj.csePollingAdminId);
    })
    .filter(
      (v, i, a) =>
        a.findIndex((v2) => v2.csePollingAdminId === v.csePollingAdminId) === i
    );

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"acces-autorises"} />
          <CseLayout
            title={"Accès autorisés"}
            loading={isLoading}
            NoResultText={"Vous n’avez pas encore créé d’accès"}
            buttonText={"Créer un accès"}
            onClick={() => {
              setIsModify(false);
              setIsOpenModal(true);
              setInitialPollingAdmin({});
            }}
            showResults={!!csePollingStations.length}
            additionalActions={
              <>
                <CustomButton
                  style={{ width: "auto", marginRight: 10 }}
                  onClick={() => {
                    setOpenSendModal(true);
                  }}
                >
                  <>Programmer l'envoi</>
                </CustomButton>
                <CustomButton
                  style={{ width: "auto", marginRight: 10 }}
                  disabled={isSubmitting}
                  onClick={() => {
                    setIsSubmitting(true);
                    API.post(
                      "/sendCseAdminsCodes",
                      {
                        cseId,
                      },
                      (data: { success: boolean; data: any }) => {
                        setIsSubmitting(false);
                        if (data.success) {
                          toast.success(`Tous les codes ont été envoyés !`);
                        }
                      },
                      () => {
                        setIsSubmitting(false);
                        toast.error(`Les codes n'ont pas pu être envoyés !`);
                      }
                    );
                  }}
                >
                  <>Envoyer tous les accès</>
                </CustomButton>
              </>
            }
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                <strong style={{ fontSize: 20 }}>Bureau central</strong>
                <p>Président(s) </p>
                {centralAdmins
                  .filter((admin) => admin.isPresident)
                  .map((admin) => (
                    <div className={classes.AdminCardContent}>
                      <AdminCard
                        admin={admin}
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        handleAdminSelected={handleAdminSelected}
                        isAdmin={isAdmin}
                      />
                    </div>
                  ))}

                <p>Organisateur(s) </p>
                {centralAdmins
                  .filter(
                    (admin) => !admin.isPresident && admin.isAdmin
                  )
                  .map((admin) => (
                    <div className={classes.AdminCardContent}>
                      <AdminCard
                        admin={admin}
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        handleAdminSelected={handleAdminSelected}
                        isAdmin={isAdmin}
                      />
                    </div>
                  ))}

                <p>Assesseur(s)</p>
                {centralAdmins
                  .filter((admin) => !admin.isAdmin)
                  .map((admin) => (
                    <div className={classes.AdminCardContent}>
                      <AdminCard
                        admin={admin}
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        handleAdminSelected={handleAdminSelected}
                        isAdmin={isAdmin}
                      />
                    </div>
                  ))}
              </div>

              {csePollingStations.map((pollingStation) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    <strong style={{ fontSize: 20 }}>
                      {`${pollingStation.collegeName} : ${pollingStation.pollingStationName}`}
                    </strong>
                    <p>Président(s) </p>
                    {pollingStation.admins
                      .filter(
                        (admin) =>
                          admin.isPresident &&
                          !duplicateIds.includes(admin.csePollingAdminId)
                      )
                      .map((admin) => (
                        <div className={classes.AdminCardContent}>
                          <AdminCard
                            admin={admin}
                            isSubmitting={isSubmitting}
                            setIsSubmitting={setIsSubmitting}
                            handleAdminSelected={handleAdminSelected}
                            isAdmin={isAdmin}
                          />
                        </div>
                      ))}
                    <p>Organisateur(s) </p>
                    {pollingStation.admins
                      .filter(
                        (admin) =>
                          admin.isAdmin &&
                          !admin.isPresident &&
                          !duplicateIds.includes(admin.csePollingAdminId)
                      )
                      .map((admin) => (
                        <div className={classes.AdminCardContent}>
                          <AdminCard
                            admin={admin}
                            isSubmitting={isSubmitting}
                            setIsSubmitting={setIsSubmitting}
                            handleAdminSelected={handleAdminSelected}
                            isAdmin={isAdmin}
                          />
                        </div>
                      ))}
                    <p>Assesseur(s)</p>
                    {pollingStation.admins
                      .filter(
                        (admin) =>
                          !admin.isAdmin &&
                          !duplicateIds.includes(admin.csePollingAdminId)
                      )
                      .map((admin) => (
                        <div className={classes.AdminCardContent}>
                          <AdminCard
                            admin={admin}
                            isSubmitting={isSubmitting}
                            setIsSubmitting={setIsSubmitting}
                            handleAdminSelected={handleAdminSelected}
                            isAdmin={isAdmin}
                          />
                        </div>
                      ))}
                  </div>
                );
              })}
            </div>
          </CseLayout>
          {openSendModal && (
            <ModalOverlay
              onClick={() => {
                setOpenSendModal(false);
              }}
            >
              <Modal onClick={(e) => e.stopPropagation()}>
                <ModalClose
                  onClick={() => {
                    setOpenSendModal(false);
                  }}
                />
                <h4 style={{ textAlign: "left" }}>Programmer les envois</h4>

                <Formik
                  initialValues={{
                    sendAdminCodeDate: moment(cse.sendAdminCodeDate).isValid()
                      ? moment.utc(cse.sendAdminCodeDate)
                      : null,
                    sendStrippingKeyDate: moment(
                      cse.sendStrippingKeyDate
                    ).isValid()
                      ? moment.utc(cse.sendStrippingKeyDate)
                      : null,
                  }}
                  onSubmit={async (values) => {
                    //setIsSubmitting(true);
                    const data = {
                      sendAdminCodeDate: moment(
                        values.sendAdminCodeDate
                      ).isValid()
                        ? moment
                          .utc(values.sendAdminCodeDate)
                          .format("YYYY-MM-DD HH:mm:ss")
                        : null,
                      sendStrippingKeyDate: moment(
                        values.sendStrippingKeyDate
                      ).isValid()
                        ? moment
                          .utc(values.sendStrippingKeyDate)
                          .format("YYYY-MM-DD HH:mm:ss")
                        : null,
                    };

                    setIsSubmitting(true);
                    API.post(
                      "/sendCseAdminsCodes",
                      {
                        cseId: cse.cseId,
                        schedulled: true,
                        ...data,
                      },
                      (data: { success: boolean; data: any }) => {
                        setIsSubmitting(false);
                        setOpenSendModal(false);
                        if (data.success) {
                          cse.sendStrippingKeyDate = moment(
                            values.sendStrippingKeyDate
                          ).isValid()
                            ? moment
                              .utc(values.sendStrippingKeyDate)
                              .format("YYYY-MM-DD HH:mm:ss")
                            : null;
                          cse.sendAdminCodeDate = moment(
                            values.sendAdminCodeDate
                          ).isValid()
                            ? moment
                              .utc(values.sendAdminCodeDate)
                              .format("YYYY-MM-DD HH:mm:ss")
                            : null;
                          console.log(">>> OK");
                          toast.success(
                            `La programmation des envois a bien été enregistré`
                          );
                        }
                      },
                      () => {
                        setIsSubmitting(false);
                        toast.error(
                          `La programmation d'envoi n'a pas pu être enregistré !`
                        );
                      }
                    );
                  }}
                >
                  <Form>
                    <Label>Envoi des mots de passe</Label>
                    <DatePickerField name="sendAdminCodeDate" withTime />
                    <Label>Envoi des clefs de dépouilllement</Label>
                    <DatePickerField name="sendStrippingKeyDate" withTime />
                    <CustomButton
                      type="submit"
                      disabled={isSubmitting}
                      style={{ width: "auto" }}
                    >
                      <>Valider</>
                    </CustomButton>
                  </Form>
                </Formik>
              </Modal>
            </ModalOverlay>
          )}
          {isOpenModal && (
            <ModalOverlay
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              <Modal onClick={(e) => e.stopPropagation()}>
                <ModalClose
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                />
                <>
                  <h4 style={{ textAlign: "left" }}>
                    {`${isModify ? "Modifier" : "Ajouter"} un accès`}
                  </h4>

                  <Formik
                    initialValues={{
                      csePollingAdminFirstname: "",
                      csePollingAdminLastname: "",
                      csePollingAdminEmail: "",
                      isAdmin: "Organisateur",
                      ...initalPollingAdmin,
                    }}
                    validate={(values) => {
                      const errors = {};

                      if (!values.csePollingAdminFirstname) {
                        errors.csePollingAdminFirstname = "Requis";
                      }
                      if (!values.csePollingAdminLastname) {
                        errors.csePollingAdminLastname = "Requis";
                      }

                      if (!values.csePollingAdminEmail) {
                        errors.csePollingAdminEmail = "Requis";
                      }

                      return errors;
                    }}
                    onSubmit={async (values) => {
                      setIsSubmitting(true);
                      const pollingStationIds = extratPollingStationIds(values);
                      const errorMessage = ` ${isModify
                        ? "L'accès n'a pas pu être modifié"
                        : "L'accès n'a pas pu être créé"
                        } !`;
                      API.post(
                        "/cseRouteCRUD",
                        {
                          ...values,
                          isAdmin:
                            values.isAdmin === "Organisateur" ||
                            values.isAdmin === "Président",
                          isPresident: values.isAdmin === "Président",
                          pollingStationIds,
                          cseId,
                          isModify,
                          module: "admin",
                          path: "createCseAdmin",
                        },
                        (data: { success: boolean; data: any }) => {
                          setIsSubmitting(false);
                          if (data.success) {
                            toast.success(
                              `${isModify
                                ? "L'accès a été modifié"
                                : "Un nouvel accès a été créé"
                              } !`
                            );
                            setIsOpenModal(false);
                          } else {
                            toast.error(errorMessage);
                          }
                        },
                        () => {
                          setIsSubmitting(false);
                          toast.error(errorMessage);
                        }
                      );
                    }}
                    validateOnMount
                  >
                    {({ values }) => (
                      <Form autoComplete="off">
                        <Label>Nom *</Label>
                        <Input
                          name="csePollingAdminLastname"
                          placeholder="Francis"
                        />
                        <Error
                          style={{
                            textAlign: "left",
                          }}
                          name="csePollingAdminLastname"
                          component="div"
                        />
                        <Label>Prénom *</Label>
                        <Input
                          name="csePollingAdminFirstname"
                          placeholder="Laurent"
                        />
                        <Error
                          style={{
                            textAlign: "left",
                          }}
                          name="csePollingAdminFirstname"
                          component="div"
                        />
                        <Label>Email *</Label>
                        <Input
                          name="csePollingAdminEmail"
                          placeholder="francis.laurent@email.com"
                        />
                        <Error
                          style={{
                            textAlign: "left",
                          }}
                          name="csePollingAdminEmail"
                          component="div"
                        />
                        <div>
                          <Label>Type d'accès*</Label>
                          <Input component="select" name="isAdmin">
                            {[
                              {
                                value: "Organisateur",
                                display: "Organisateur",
                              },
                              {
                                value: "Assesseur",
                                display: "Assesseur",
                              },
                              {
                                value: "Président",
                                display: "Président",
                              },
                            ].map((x) => (
                              <option key={x.display} value={x.value}>
                                {x.display}
                              </option>
                            ))}
                          </Input>
                          <Error name="isAdmin" component="div" />
                        </div>
                        <Label>Autorisations *</Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                          }}
                        >
                          {csePollingStations.map((pollingStation) => (
                            <div style={{ display: "flex", gap: 5 }}>
                              <Field
                                name={
                                  "pollingStation" +
                                  pollingStation.pollingStationId
                                }
                                type="checkbox"
                                id={
                                  "pollingStation" +
                                  pollingStation.pollingStationId
                                }
                                checked={
                                  !!values[
                                  "pollingStation" +
                                  pollingStation.pollingStationId
                                  ]
                                }
                                value={
                                  !!values[
                                  "pollingStation" +
                                  pollingStation.pollingStationId
                                  ]
                                }
                              />
                              <span>
                                {`${pollingStation.collegeName} : ${pollingStation.pollingStationName}`}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <CustomButton
                            style={{ width: "auto" }}
                            white
                            onClick={() => {
                              setIsOpenModal(false);
                            }}
                          >
                            <>Retour</>
                          </CustomButton>
                          <CustomButton
                            type="submit"
                            disabled={isSubmitting}
                            style={{ width: "auto" }}
                          >
                            <>{`${isModify ? "Modifier" : "Ajouter"
                              }  l'accès`}</>
                          </CustomButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              </Modal>
            </ModalOverlay>
          )}
        </>
      )}
    </>
  );
};

export default CseAccess;
